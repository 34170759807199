* {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  background-image: linear-gradient(to right, #f59d0e85 0%, #f59d0e 100%);
}

.Opacity {
  width: 59%;
  position: absolute;
  top: 15%;
  left: 0%;
  opacity: 0.2;
  font-size: 50px;
}

.heading {
  text-align: center;
  padding: 13px;
  border-left: 12px solid #000;
  border-top-left-radius: 10px;
  border-top: 12px solid #000;
  border-right: 12px solid #000;
  border-top-right-radius: 10px;
}

.heading h4 {
  margin: 0;
}

.heading img {
  width: 22%;
}

#heading {
  text-transform: uppercase;
  color: #673ab7;
  font-weight: normal;
}

#msform {
  text-align: center;
  position: relative;
}

#msform fieldset {
  background: rgb(69, 5, 59);
  border: 0 none;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  position: relative;
  padding: 1px 12px 20px 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.form-card {
  text-align: center;
  background-color: #ffffff;
  padding: 20px 0;
}

.Font{
    font-size: 25px;
}

.form-card label {
  font-size: 1.5rem;
  font-weight: 500;
}

.form-table{
    /* margin: auto;
    padding-bottom: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.table_align{
text-align: right;
/* padding-right: 30px; */
font-size: 19px;
font-weight: 700;
width: 45%;
}

.table_right{
    text-align: left;
    /* padding-left: 30px; */
    font-weight: 500;
    line-height: normal;
    width: 45%;
/* width: 60%; */
}

.colan_th{
  /* padding: 0 20px; */
  width: 3%;
}

.form-table tr{
  line-height: 50px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.custom-flex {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

/* .custom-flex p{
    width: 100px;
} */

#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform input,
#msform textarea {
  padding: 8px 15px;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 80%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  background-color: #eceff1;
  font-size: 16px;
  letter-spacing: 1px;
  margin-top: 20px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #673ab7;
  outline-width: 0;
}

#msform .action-button {
  width: 100px;
  background: #f5a800;
  font-weight: bold;
  color: black;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 0px 10px 5px;
  float: right;
  font-family: sans-serif;
  transition: all 0.5s;
}

#msform .action-button:hover,
#msform .action-button:focus {
  background-color: #e31e24;
  color: #fff;
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background-color: #000000;
}

.card {
  width: 70%;
  margin: auto;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  background: #ffffff;
  margin-top: 18px;
  border-radius: 10px;
}

.fs-title {
  font-size: 25px;
  color: #673ab7;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}

.margdiv {
  transition: all 0.1s;
  width: 40%;
  margin: auto;
}

.margdiv:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 13px;
}

.purple-text {
  color: #673ab7;
  font-weight: normal;
}

.steps {
  font-size: 25px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right;
  margin-top: 10px;
  background-color: #ffffff;
}

.fieldlabels {
  color: gray;
  text-align: left;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #673ab7;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f13e";
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f030";
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c";
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #673ab7;
}

.progress {
  height: 20px;
}

.progress-bar {
  background-color: #673ab7;
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

.star-rating {
  display: flex;
  flex-direction: row-reverse;
  font-size: 1.5em;
  justify-content: center;
  gap: 21px;
  padding: 0 0.2em;
  text-align: center;
  margin: auto;
  background-color: #ffffff;
}

.star-rating input {
  display: none;
}

.star-rating label {
  color: #ccc;
  cursor: pointer;
  font-size: 40px;
}

.star-rating :checked ~ label {
  color: #f90;
}

.star-rating label:hover,
.star-rating label:hover ~ label {
  color: #fc0;
}

.circle {
  width: 200px;
  height: 200px;
  line-height: 200px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
  right: 24%;
  top: 64%;
}

.blue {
  background-color: #ffffff1f;
}

.borderCol {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.Submit {
  border-left: 12px solid #000;
  border-bottom: 12px solid #000;
  border-right: 12px solid #000;
  border-top: 1px solid #000;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10%;
}

.Toastify__toast-body {
  color: #000000;
}

.Thankyou {
  text-align: center;
  padding: 13px;
  border-left: 12px solid #000;
  border-bottom-left-radius: 10px;
  border-bottom: 12px solid #000;
  border-right: 12px solid #000;
  border-top: 1px solid #000;
  border-bottom-right-radius: 10px;
  height: 250px;
}

.ThankyouImage {
  width: 35%;
  height: 70%;
}

.textboxsize {
  height: 90px;
  width: 285px;
}

.pageindicator {
  font-size: 16px;
  text-align: center;
  color: white;
  font-family: sans-serif;
  padding: 20px;
  /* margin: 20px auto 0; */
  /* background: #f5a800; */
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: fit-content;
  position: absolute;
}

.headerdetailsCSS{
  letter-spacing: 3px;
  font-size: 27px;
}

/* Media Queries */

/* <576px */
@media (max-width: 575px) {
  .heading h1 {
    font-size: 25px;
  }

  .fs-title {
    font-size: 18px;
  }

  .margdiv {
    padding: 17px 0;
    width: 95%;
  }

  .ThankyouImage {
    width: 60%;
    height: 60%;
  }

  .heading img {
    width: 50%;
  }

  #msform input,
  #msform textarea {
    width: 80%;
  }

  .card {
    width: 90%;
  }

  .star-rating {
    font-size: 1.2em;
    gap: 10px;
  }

  .circle {
    right: 10%;
    top: 60%;
    width: 150px;
    height: 150px;
    line-height: 150px;
  }

}

/* ≥576px */
@media (min-width: 576px) {
  .heading h1 {
    font-size: 30px;
  }

  .fs-title {
    font-size: 20px;
  }

  .margdiv {
    padding: 17px 0;
    width: 75%;
  }

  .heading img {
    width: 50%;
  }


}

/* ≥768px */
@media (min-width: 768px) {
  .heading h1 {
    font-size: 32px;
  }

  .fs-title {
    font-size: 22px;
  }

  .margdiv {
    width: 60%;
  }

  .heading img {
    width: 40%;
  }

}

/* ≥992px */
@media (min-width: 992px) {
  .heading h1 {
    font-size: 34px;
  }

  .fs-title {
    font-size: 24px;
  }

  .margdiv {
    width: 40%;
  }

  .heading img {
    width: 35%;
  }
}

/* ≥1200px */
@media (min-width: 1200px) {
  .heading h1 {
    font-size: 35px;
  }

  .fs-title {
    font-size: 25px;
  }

  .margdiv {
    padding: 7px 0;
    width: 40%;
  }

  .heading img {
    width: 20%;
  }
}

/* ≥1400px */
@media (min-width: 1400px) {
  .heading h1 {
    font-size: 40px;
  }

  .fs-title {
    font-size: 30px;
  }

  .margdiv {
    width: 40%;
  }

  .heading img {
    width: 20%;
  }
}

@media screen and (max-width: 600px) {
  .form-table tr {
      margin-bottom: 10px;
  }

  .form-table th,
  .form-table td {
      text-align: right;
  }

  .form-table th::before {
      content: attr(data-label);
      font-weight: bold;
  }

  .form-table th {
      background-color: transparent;
      text-align: left;
  }

  .form-table th[data-label="HB Booking ID"]::before {
      content: "HB Booking ID";
  }
  .form-table th[data-label="Guest Name"]::before {
      content: "Guest Name";
  }
  .form-table th[data-label="Property Name"]::before {
      content: "Property Name";
  }
  .form-table th[data-label="Check-In"]::before {
      content: "Check-In";
  }
  .form-table th[data-label="Check-Out"]::before {
      content: "Check-Out";
  }
}
